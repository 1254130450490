<div style="
    height: 100%;
    width: 100%;
    min-height: 950px;
    background-color: white;
    padding-top: 1px;
  ">
  <div class="opacity-holder"></div>
  <div class="banner-top-sec"></div>
</div>

<div style="overflow-y: visible; background-color: white;">
  <header class="pdplg-header">
    <div class="pdplg-container">
      <div class="pdplg-logo pdplg-withbg">
        <img src="../assets/images/paydayportal-logo.svg" alt="logo" />
      </div>

      <div class="pdplg-navigation">
      </div>
      <div class="pdplg-logo-right">
        <a href="https://gratuitysolutions.com/index.html">
          <img src="../assets/images/gratuity_solutions_m.png"
               class="pdplg-logo_mobile" />
        </a>
      </div>
    </div>
  </header>

  <section >
    <div class="pdplg-container">
      <div class="pdplg-contactform">
        <form [formGroup]="form" (ngSubmit)="signUp()">
          <div class="centralized" style="background-color:white">
            <div class="transparentframe">
              
              <div class="pdplg-title">
                <hr />
                <h3 class="mb-4 pb-2 pb-md-0 mb-md-5">{{ 'signUp.signUp' | translate }}</h3>
                <p>({{ "signUp.registrationOnly" | translate }})</p>
              </div>
              <div style="margin-top: 20px">

                <div class="row">
                  <div class="col-md-6 mb-4">

                    <div data-mdb-input-init class="d-flex flex-column">
                      <label class="gsform-label" for="firstName">{{ 'signUp.firstName' | translate }}</label>
                      <input autocomplete="new"
                             id="firstName"
                             type="text"
                             pInputText
                             formControlName="firstName"
                             class="form-input"
                             style="width: 80%" />
                    </div>
                    <div class="gsform-error" >
                      <div id="firstNameValidation"
                           *ngIf="  !form.controls['firstName'].valid &&  form.controls['firstName'].dirty" translate>
                        signUp.firstNameRequired
                      </div>
                    </div>

                  </div>
                  <div class="col-md-6 mb-4">

                    <div data-mdb-input-init class="d-flex flex-column">
                      <label class="gsform-label" for="lastName">{{ 'signUp.lastName' | translate }}</label>
                      <input autocomplete="new"
                             id="lastName"
                             type="text"
                             pInputText
                             formControlName="lastName"
                             class="form-input"
                             style="width: 80%" />
                    </div>
                    <div class="gsform-error" >
                      <div id="lastNameValidation"
                           *ngIf="!form.controls['lastName'].valid && form.controls['lastName'].dirty"
                           translate>
                        signUp.lastNameRequired
                      </div>
                    </div>

                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="d-flex flex-column">
                      <label class="gsform-label" for="user">{{ 'signUp.createUsername' | translate }}</label>
                      <input autocomplete="new"
                             id="user"
                             type="text"
                             pInputText
                             formControlName="user"
                             class="form-input"
                             style="width: 80%" />
                    </div>
                    <div class="gsform-error" >
                      <div id="userValidation"
                           *ngIf="
                  !form.controls['user'].valid && form.controls['user'].dirty"
                           translate>
                        signUp.usernameRequired
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="d-flex flex-column">
                      <label class="gsform-label" for="password">{{ 'signUp.password' | translate }}</label>
                      <input autocomplete="new-password"
                              id="password"
                              type="password"
                              pPassword
                              [feedback]="false"
                              [showPassword]="showPassword"
                              formControlName="password"
                              class="form-input"
                              style="width: 80%" />
                      <span *ngIf="!showPassword"
                            class="pointer show-hide-password-icon"
                            (click)="showPass()">
                        <i class="fas fa-eye"></i>
                      </span>
                      <span *ngIf="showPassword"
                            class="pointer show-hide-password-icon"
                            (click)="showPass()">
                        <i class="fas fa-eye-slash"></i>
                      </span>
                    </div>
                    <div class="gsform-error" >
                      <div id="passwordValidation"
                           *ngIf="
                  !form.controls['password'].valid &&
                  form.controls['password'].dirty"
                           translate>
                        signUp.passwordRequired
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="d-flex flex-column">
                      <label class="gsform-label" for="confirmPass">{{ 'signUp.confirmPass' | translate }}</label>
                      <input autocomplete="new-password"
                             id="confirmPass"
                             type="password"
                             pPassword
                             [feedback]="false"
                             [showPassword]="showConfirmPassword"
                             formControlName="confirmPass"
                             class="form-input"
                             style="width: 80%" />
                      <span *ngIf="!showConfirmPassword"
                            class="pointer show-hide-password-icon"
                            (click)="showConfirmPass()">
                        <i class="fas fa-eye"></i>
                      </span>
                      <span *ngIf="showConfirmPassword"
                            class="pointer show-hide-password-icon"
                            (click)="showConfirmPass()">
                        <i class="fas fa-eye-slash"></i>
                      </span>
                    </div>
                    <div class="gsform-error" >
                      <div id="confirmPassValidation"
                           *ngIf="!form.controls['confirmPass'].valid"
                           translate>
                        signUp.confirmPassRequired
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <div class="d-flex flex-column">

                      <label class="gsform-label" for="verificationCode">{{ 'signUp.verificationCodeRequired' | translate }}</label>
                      <ng-otp-input [formCtrl]="verificationCode"
                                    (onInputChange)="onOTPChange($event)"
                                    [config]="otpConfig"
                                    autocomplete="verification-code"
                                    formControlName="verificationCode"
                                    id="verificationCode"
                                    ngDefaultControl
                                    class="form-input">


                      </ng-otp-input>
                    </div>
                    <div class="gsform-error">
                      <div id="verificationCodeValidation"
                           *ngIf="!form.controls['verificationCode'].valid && form.controls['verificationCode'].dirty"
                           translate>
                        signUp.verificationCodeRequired
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 mb-4">
                    <div class="d-flex flex-column">
                      <label class="info-label">
                        A verification code has been sent to your phone or email.<br/>
                        If you don't see it, please check your spam or junk folder.
                      </label>
                    </div>
                  </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <p-checkbox id="termsAndConditions"
                                    [binary]="true"
                                    formControlName="termsAndConditions"
                                    class="form-input">
                        </p-checkbox>
                        <span for="termsAndConditions"
                              style="
                            font-size: 12px;
                            padding: 10px;
                            width: 305px;
                          ">
                          {{ "signUp.acceptTermsAndConditions" | translate }}
                          <span class="span-terms-and-conditions"
                                (click)="openTerms()">
                            "{{"signUp.acceptTermsAndConditionsClickable" | translate}}"
                          </span>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 mb-4">
                        <button class="button"
                                id="signin"
                                pButton
                                type="submit"
                                label="{{ 'signUp.signUp' | translate }}"
                                [disabled]="!form.valid || progress">
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
          </div>
          <span style="margin:0 auto;"><label>{{"signUp.copyright" | translate}}</label></span>
        </form>
      </div>
    </div>
    <div style="clear: both"></div>
  </section>
</div>



<p-dialog [(visible)]="confirmationDialogVisible"
          header="{{ 'signUp.confirmation' | translate }}"
          modal="true"
          [closable]="true"
          class="signup">
  {{ "signUp.confirmationMessage" | translate }}
  <p-footer>
    <button type="button"
            pButton
            icon="pi pi-check"
            (click)="closeConfirmationDialog()"
            label="{{ 'general.ok' | translate }}"></button>
  </p-footer>
</p-dialog>

<!--<iframe class="pdplg-iframe"
        id="iframeURL"
        name="iframeURL"
        [src]="contentURL"
        allowtransparency="true"
        scrolling="yes"
        style="width: 100%; border: none; height: calc(100vh - 10px)"></iframe>-->
