import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../app/menuItems/security/login.component';
import { environment } from '../environments/environment';
import { PayDayLoginComponent } from './menuItems/security/payDayLogin.component';
import { Session } from './common/entity/entities';
import { SignUpComponent } from './menuItems/security/signUp.component';

const routes: Routes = [
  {
    path: 'login',
    component:
      environment.portalCode == 'PAP' ? LoginComponent : PayDayLoginComponent,
  },
  {
    path: 'auth-error',
    component: PayDayLoginComponent,
  },
  {
    path: 'auth-callback',
    component: PayDayLoginComponent,
  },
  { path: 'signup', component: SignUpComponent },
  { path: 'home', redirectTo: Session.landingPage, pathMatch: 'full' },
  {
    path: 'integration',
    loadChildren: () =>
      import('../app/menuItems/customerWizard/customerWizard.module').then(
        (m) => m.CustomerWizardModule
      ),
  },
  {
    path: 'security',
    loadChildren: () =>
      import('../app/menuItems/security/security.module').then(
        (m) => m.SecurityModule
      ),
  },
  {
    path: 'companygroup',
    loadChildren: () =>
      import('../app/menuItems/companyGroup/companyGroup.module').then(
        (m) => m.CompanyGroupModule
      ),
  },
  {
    path: 'paymentintegrations',
    loadChildren: () =>
      import(
        '../app/menuItems/paymentIntegrations/paymentIntegrations.module'
      ).then((m) => m.PaymentIntegrationsModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('../app/menuItems/dashboard/dashboard.module').then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: 'controlpanel',
    loadChildren: () =>
      import('../app/menuItems/controlPanel/controlPanel.module').then(
        (m) => m.ControlPanelModule
      ),
  },
  {
    path: 'reports',
    loadChildren: () =>
      import('../app/menuItems/reporting/reporting.module').then(
        (m) => m.ReportingModule
      ),
  },
  {
    path: 'help',
    loadChildren: () =>
      import('../app/menuItems/help/help.module').then((m) => m.HelpModule),
  },
  {
    path: 'timeattend',
    loadChildren: () =>
      import('../app/menuItems/timeAttendence/timeAttendence.module').then(
        (m) => m.TimeAttendeceModule
      ),
  },
  {
    path: 'reporting',
    loadChildren: () =>
      import('../app/menuItems/reportingAdmin/reportingAdmin.module').then(
        (m) => m.ReportingAdminModule
      ),
  },
  {
    path: 'finance',
    loadChildren: () =>
      import('../app/menuItems/finance/finance.module').then(
        (m) => m.FinanceModule
      ),
  },
  {
    path: 'legalDocuments',
    loadChildren: () =>
      import('../app/menuItems/legal-documents/legal-documents.module').then(
        (m) => m.LegalDocumentsModule
      ),
  },
  {
    path: 'billing',
    loadChildren: () =>
      import('../app/menuItems/billing/billing.module').then(
        (m) => m.BillingModule
      ),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
