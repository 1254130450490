<div class="row form-property">
  <div
    *ngIf="!property.vertical"
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
    style="margin-top: -20px"
  >
    <ng-container *ngIf="!leftLabel">
      <span>
        <label
          class="gs-float-label"
          [ngClass]="{'error-color':notValid}"
          style="margin-left: 5px"
          >{{label}}</label
        >
        <ul
          *ngIf="property.options"
          class="list-unstyled list-inline ui-inputwrapper-focus"
          style="margin-top: -10px; margin-left:-20px width: 100%"
        >
          <li
            *ngFor="let option of property.options;let i=index"
            [ngStyle]="{'min-width': option.label.length*20 + 'px'}"
          >
            <label class="container">
              {{option.label}}
              <input
                type="radio"
                name="property.name"
                [(ngModel)]="value"
                [value]="option.value"
                (ngModelChange)="changeV($event)"
              />
              <span class="checkmark"></span>
            </label>
          </li>
        </ul>
      </span>
    </ng-container>
    <ng-container *ngIf="leftLabel">
      <label class="master-font" [ngClass]="{'error-color':notValid}"
        >{{label}}</label
      >
      <span class="float-right"> </span>
    </ng-container>
  </div>
  <div
    *ngIf="property.vertical"
    class="col-lg-{{tooltip?11:12}}"
    [ngClass]="{'no-padding-right':tooltip}"
  >
    <ng-container
      *ngIf="!property.hideNoOptions || property.hideNoOptions && property.options && property.options.length"
    >
      <ng-container *ngIf="!leftLabel">
        <span>
          <label class="gs-float-label" [ngClass]="{'error-color':notValid}"
            >{{label}}</label
          >
          <ul
            *ngIf="property.options"
            class="list-unstyled ui-inputwrapper-focus"
            style="margin-top: 0px"
            [style.overflow-y]="property.overflowY"
            [style.max-height]="property.maxHeight"
          >
            <li *ngFor="let option of property.options;let i=index">
              <label
                class="container {{property.noLeftMargin==true?'no-left-margin':''}}"
                style="max-width: 2000px !important"
              >
                {{option.label}}
                <input
                  type="radio"
                  name="property.name"
                  [(ngModel)]="value"
                  [value]="option.value"
                  (ngModelChange)="changeV($event)"
                />
                <span class="checkmark"></span>
              </label>
            </li>
          </ul>
        </span>
      </ng-container>
      <ng-container *ngIf="leftLabel">
        <label class="master-font" [ngClass]="{'error-color':notValid}"
          >{{label}}</label
        >
        <span class="float-right"> </span>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="property.hideNoOptions && property.options && !property.options.length"
    >
      <div
        [style.color]="property.noOptionsColor"
        [ngClass]="{'form-textarea':property.rowspan}"
      >
        {{property.noOptionsLabel}}
      </div>
    </ng-container>
  </div>
  <div *ngIf="tooltip" class="col-lg-1 property-tooltip">
    <span pTooltip="{{property.tooltip}}">
      <i class="fas fa-info-circle master-color"></i>
    </span>
  </div>
</div>
