import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  Injector,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { CacheService } from 'ng2-cache';
import { Ng2CacheModule } from 'ng2-cache';
import { AppComponent } from './app.component';
import { AppConfig } from '../app/app.config';
import { LoginComponent } from '../app/menuItems/security/login.component';
import { AppRoutingModule } from './app-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import {
  HttpClientModule,
  HttpClient,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';

import { CommonGratModule } from './common/common.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ConfirmationService } from 'primeng/api';
import { SignalRModule, SignalRConfiguration } from 'ng2-signalr';
import { MyHttpInterceptor } from './common/client/httpInterceptor';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PayDayLoginComponent } from './menuItems/security/payDayLogin.component';
import { SignUpComponent } from './menuItems/security/signUp.component';
import { DatePipe, DecimalPipe } from '@angular/common';
import { GsUIStatePipe, TimeFormatUSPipe } from './common/pipes/pipes';
import { RecaptchaModule } from 'ng-recaptcha';
import { UserManualDirective } from './common/directives/userManual';
import { ErrorInterceptor } from './common/client/error.interceptor';
import { NgOtpInputModule } from 'ng-otp-input';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export let AppInjector: Injector;
export let helpPath: string;

export function createConfig(): SignalRConfiguration {
  const config = new SignalRConfiguration();
  config.hubName = 'ConfigurationWizardHub';
  config.logging = false;
  return config;
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    Ng2CacheModule,
    FormsModule,
    ReactiveFormsModule,
    SignalRModule.forRoot(createConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    CommonGratModule,
    NgxSpinnerModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
      registrationStrategy: 'registerImmediately',
    }),
    RecaptchaModule,
    DragDropModule,
    NgOtpInputModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    LoginComponent,
    PayDayLoginComponent,
    SignUpComponent,
    UserManualDirective,
  ],
  exports: [FormsModule, ReactiveFormsModule, UserManualDirective],
  providers: [
    HttpClient,
    AppConfig,
    CacheService,
    ConfirmationService,
    DatePipe,
    GsUIStatePipe,
    DecimalPipe,
    UserManualDirective,
    TimeFormatUSPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    AppInjector = this.injector;
  }
}
